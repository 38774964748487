let root = document.documentElement

function attach({ clientX, clientY }) {
  root.style.setProperty('--button-position', 'fixed')
  root.style.setProperty('--mouse-x', clientX - 50 + 'px')
  root.style.setProperty('--mouse-y', clientY - 10 + 'px')
  document.querySelector('#mouse-x').innerText = clientX
  document.querySelector('#mouse-y').innerText = clientY
}

let attached = false

window.chaseMouse = function chaseMouse() {
  if (!attached) {
    document.body.addEventListener('mousemove', attach)
    document.querySelector('#instruction').style.display =
      'inline-block'

    attached = true
  } else {
    document.body.removeEventListener('mousemove', attach)
    root.style.setProperty('--button-position', 'initial')
    document.querySelector('#mouse-x').innerText = 'null'
    document.querySelector('#mouse-y').innerText = 'null'
    attached = false
  }
}
